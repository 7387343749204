var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"96c3535f420bbc74215eb412ef64d03481c02d8a"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/njut/v0.2.39-96c3535f";

import * as Sentry from '@sentry/nextjs';

const DSN_STAGING =
  'https://6a884ee5628b4c2c8a6832b5d00a3317@o216259.ingest.sentry.io/1521597';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_URL || DSN_STAGING;
const isStaging = SENTRY_DSN === DSN_STAGING;

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: isStaging ? 0 : 0.1,
  ignoreErrors: [
    'gtag is not defined',
    'gtag',
    'Cancel rendering route',
    'The operation is insecure.',
    'Unexpected token ?',
    "Unexpected token '?'",
    /Failed to fetch/,
    /ResizeObserver/,
    'Could not load "util".',
    'Load failed',
    'Illegal invocation',
    "Cannot read properties of null (reading 'postMessage')",
    "Cannot read property 'startTime' of null"
  ],
  environment: isStaging ? 'staging' : 'production'
});
